.App {
  background-color: #f4ecf7; /* A lighter pink shade for a softer look */
  color: #4a4a4a; /* A darker color for better readability */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 20px;
  text-align: center;
}

h1 {
  color: #ff85a2; /* A color that matches the pink theme */
  margin-bottom: 1em;
}
.contract-address {
  margin: 1em 0;
}

.contract-input {
  width: 80%; /* Responsive width */
  padding: 10px;
  margin: 0 auto; /* Center the textbox */
  border: 2px solid #ff85a2; /* Pink border */
  color: #ff85a2; /* Pink text */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1em;
  border-radius: 8px;
  text-align: center;
  background-color: #fff; /* White background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: block; /* Ensure it's a block-level element */
}

/* Adjust the width of the textbox for smaller screens */
@media (max-width: 768px) {
  .contract-input {
    width: 95%; /* Make the textbox wider on smaller screens */
  }
}

.token-info {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2em;
  padding: 20px;
}
.waifupepe-image:hover {
  transform: scale(1.03); /* Slightly increase the size */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance the shadow for a lifted effect */
  filter: brightness(1.1); /* Slightly increase brightness to draw attention */
}
.token-info h2 a {
  color: #ff85a2;
  text-decoration: none;
}

.token-info h2 a:hover {
  text-decoration: underline;
}

.token-info ul {
  text-align: left;
  padding: 0;
}

.token-info li {
  margin-bottom: 0.5em;
}

.image-container {
  margin: 2em 0;
}

.waifupepe-image {
  max-width: 80%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 2em;
}

.link {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #ff85a2;
  padding: 10px 20px;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.link:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .links {
    flex-direction: column;
  }

  .link {
    margin-bottom: 1em;
  }
}
.App-header {
  background-color: #ff85a2;
  padding: 1em;
  margin-bottom: 2em;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.App-title {
  color: white;
  text-align: center;
  margin: 0;
  font-size: 2.5em;
}


/* Footer with robot emoji */
.App-footer {
  padding: 1em;
  text-align: center;
}

.App-link.robot {
  font-size: 2em; /* Larger emoji size */
  color: #ff85a2; /* Same pink color as the header for consistency */
  text-decoration: none;
  transition: color 0.3s ease;
}

.App-link.robot:hover {
  color: #ffbfd3; /* Lighter pink on hover for interaction feedback */
}
